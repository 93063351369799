html,
body {
  background-color: #101728;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
  scrollbar-width: 5px;
}
html::-webkit-scrollbar {
  width: 5px;
}
html::-webkit-scrollbar-track {
  background-color: transparent;
}
/* Scroll barın kaydırılabilir kısmı */
html::-webkit-scrollbar-thumb {
  background-color: #9340ff; /* Renk gradyanı */
}

/* Scroll bar üzerine gelindiğinde */
html::-webkit-scrollbar-thumb:hover {
  background-color: #9340ff; /* Hover rengi */
}
/*uygulamanın topbar kısmı*/
.header {
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 10px;
  gap: 5px;
}
/* şirket logosu*/
.icon {
  width: 40px;
}

.container {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: calc(100vh - 110px - 30px);
  width: auto;
  padding: 10px;
}
/* ana sayfadaki bağlı kal yazısının font ayarları*/
.yazi {
  color: white;
  font-weight: bold;
  justify-content: center;
  font-size: 70px;
  text-align: center;
}

/*ana sayfada ki resim*/
.homepict {
  display: block;
  justify-content: end;
  align-items: end;
  width: 400px;
  height: 400px;
  border-radius: 5px;
  transition: 0.6s;
}

.homepict:hover {
  box-shadow: 0 0 50px #9340ff;
}

/* yazı ve fotoğrafın aynı hizada olması için eklediğimiz container içindeki row*/
.row {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
/*
.button {
  width: 200px;
  font-family: "Poppins", sans-serif;
  height: 50px;
  border: 2px solid #ff3c5f;
  border-radius: 40px;
  background: linear-gradient(90deg, #9340ff, #ff3c5f);
  color: #101728;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  transition: 0.5s;
}
  
.button:hover {
  background: color(#101728);
  border: 2px solid #ff3c5f;
  box-shadow: 0 0 10px whitesmoke;
}
  */
.detailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: fullscreen;
  width: 70%;
  color: white;
}

h2 {
  color: #9340ff;
  size: 40%;
  font-size: 40px;
  text-align: center;
}

.detailDescription {
  text-align: center;
  color: white;
  font-size: larger;
  size: 40%;
  font-size: 30px;
}
.detailRow {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 20px;
}

.nav-link {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

.nav-link:hover {
  border-bottom: 2px solid#fff;
  text-shadow: 0 0 50px #9340ff;
}
.main-content {
  flex: 1;
  padding: 20px;
}

footer {
  display: flex;
  color: white;
  width: calc(100vw - 30px);
  align-items: center;
  justify-content: center;
  height: 30px;
}
.details-page {
  overflow: hidden; /* Aşırı kaydırmayı önlemek için */
}

.details-page > div {
  height: 100vh; /* Her detay bileşeni tam ekranı kaplayacak şekilde ayarlandı */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.detailPict {
  display: block;
  justify-content: end;
  align-items: end;
  border: 5px solid #9340ff;
  padding: 2px;
  background-color: white;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  transition: 0.6s;
}
.contactContext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.contactYapi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 7px;
}

.contactContainer {
  margin: 0;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactInfo {
  border: 2px solid #9340ff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;
  height: 70%;
  transition: 0.6s;
}

.contactInfo:hover {
  box-shadow: 0 0 70px #9340ff;
}
.contact-icon {
  width: 70px;
}
.contact-title {
  color: white;
  font-size: 30px;
}
.contact-insta {
  color: white;
  text-decoration: none;
  font-size: 80%;
}
h3 {
  color: white;
}

@media (orientation: portrait) {
  /*main content*/
  body {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: small !important;
  }
  h3 {
    font-size: medium !important;
  }
  .contact-icon {
    width: 2px;
  }
  .header {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-between;
    align-items: center;
    height: 40%;
    gap: 5px;
  }
  .header h1 {
    font-size: 30px;
  }
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 20px;
  }

  .nav-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 60%;
  }

  .detailRow {
    flex-direction: column;
    align-items: flex-start;
  }
  /*contact-screen*/
  .contactInfo {
    display: flex !important;
    flex-direction: column !important;
    padding: 3%;
    width: 90%; /* Genişliği tam sayfa yapar */
    height: 70vh !important; /* İçeriğe göre yükseklik ayarlanır */
    margin-top: 5%;
  }

  .contactContext {
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .contactYapi {
    width: 100% !important;
    height: 40% !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .contactContainer {
    margin: 0 !important;
    padding: 0 !important;
    height: auto; /* Yükseklik içeriğe göre ayarlanır */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-icon {
    width: 60px !important;
  }
  .contact-title {
    color: white;
    font-size: 20px !important;
  }
  .footer {
    align-items: center;
    width: 100%;
  }
  .mobil-baslik {
    font-size: small;
  }
  /*detail screen*/
  .detailContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 100vh;
    width: 100%;
    color: white;
  }

  .detailDescription {
    text-align: center !important;
    color: white;
    size: 80%;
    font-size: 80%;
  }
  .detailPict {
    justify-content: center !important;
    width: 50% !important;
    height: auto;
    border-radius: 100% !important;
    transition: 0.6s;
  }
  .detailH2 {
    font-weight: bold;
    font-size: 80%;
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }
  .detailDescTitle {
    font-size: 30px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
  }
  .detailDownDesc {
    font-size: 70%;
    text-align: center;
  }
  .detailRow img {
    align-items: center;
    border: 2px solid #9340ff;
    padding: 2px;
    justify-content: center;
    order: -1;
    size: 30%;
    margin-left: 3%;
  }
  /* home screen*/
  .homepict {
    display: flex;
    width: 100% !important;
    height: auto !important;
  }
  .yazi {
    font-size: 30px !important;
    size: 70%;
  }
  /*
  .button {
    width: 200px;
    font-family: "Poppins", sans-serif;
    height: 50px;
    border: 2px solid #ff3c5f;
    border-radius: 40px;
    background: linear-gradient(90deg, #9340ff, #ff3c5f);
    color: #101728;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    transition: 0.5s;
  }
    */
  hr {
    width: 100vw;
    align-items: center;
  }
}
